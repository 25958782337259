import React from 'react'
import { Dashboard, SuperAdminDashHome } from 'containers'

const SupAdminDashHomePage = () => {
  return (
    <>
      <Dashboard component={SuperAdminDashHome}/>
    </>
  )
}

export default SupAdminDashHomePage