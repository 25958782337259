import React from 'react'
import { Dashboard, MyAccount } from 'containers'

const MyAccountPage = () => {
  return (
    <>
        <Dashboard component={MyAccount}/>
    </>
  )
}

export default MyAccountPage