import React from 'react'
import { Dashboard, DashboardProject } from 'containers'

const DashboardProjectPage = () => {
  return (
    <>
        <Dashboard component={DashboardProject}/>
    </>
  )
}

export default DashboardProjectPage