import React from 'react'
import "./MyAccountBanner.scss"

const MyAccountBanner = () => {
  return (
    <div className='my_account_banner'>
        <div className="my_account_banner_text sub_heading">
            My Acccount
        </div>
    </div>
  )
}

export default MyAccountBanner