import { BuyCarbonCreditCon, Dashboard } from "containers";
import React from "react";

const BuyCarbonCreditPage = () => {
  return (
    <>
      <Dashboard component={BuyCarbonCreditCon} />
    </>
  );
};

export default BuyCarbonCreditPage;
