import React from "react";
import { Dashboard, DashboardWalletCon } from "containers";

const DashboardWalletPage = () => {
  return (
    <>
      <Dashboard component={DashboardWalletCon} />
    </>
  );
};

export default DashboardWalletPage;
