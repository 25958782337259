import { Landing } from "containers";
import React from "react";

const LandingPage = () => {
  return (
    <>
      <Landing />
    </>
  );
};

export default LandingPage;
