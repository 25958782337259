import { Dashboard, DashboardStaff } from 'containers'
import React from 'react'

const DashboardStaffPage = () => {
  return (
    <>
        <Dashboard component={DashboardStaff}/>
    </>
  )
}

export default DashboardStaffPage